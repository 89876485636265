/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

// UI lib comoponents
import { Container, Row, Col } from 'react-grid-system';

// Local UI components

// Redux actions and selectors
// @TODO: If using Redux, import actions and selectors

// Helpers & utils
// @TODO: Import any helper or service here

// Style
import './index.scss';
import Video from '../../../shared/UIKit/Video';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function BannerSection() {
  /* ******************************** CONSTANTS ******************************* */

  /* ************************ ESSENTIAL LOCAL VARIABLES *********************** */

  /* ********************************** HOOKS ********************************* */
  const [openVideo, setOpenVideo] = useState(false);

  // State

  // Other hooks like reux or localization ...
  const { t } = useTranslation();
  const {
    bannerSection: { title, description },
  } = t('financialEngineering', { returnObjects: true });

  /* ***************************** LOCAL VARIABLES **************************** */

  /* ******************************** CALLBACKS ******************************* */
  /**
   * Computes the perimeter
   */

  /* ***************************** RENDER HELPERS ***************************** */

  /* ******************************** RENDERING ******************************* */

  return (
    <Container className="banner-section" fluid>
      <div className="container">
        <Row align="center" justify="center" flex-direction="column">
          <h1 className="banner-section-title">{title}</h1>
          <Col lg="content" justify="center">
            <p className="banner-section-description">{description}</p>
          </Col>
        </Row>
        <Container />
      </div>
      {openVideo === true && (
        <Video openVideo={openVideo} setOpenVideo={setOpenVideo} />
      )}
    </Container>
  );
}

BannerSection.propTypes = {};

BannerSection.defaultProps = {};

export default BannerSection;
