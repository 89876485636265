/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';
import { useTranslation } from 'react-i18next';
// UI lib comoponents
import { Container } from 'react-grid-system';

// Local UI components
// @TODO: Import other locally-made dependencies here

// Redux actions and selectors
// @TODO: If using Redux, import actions and selectors

// Helpers & utils
// @TODO: Import any helper or service here

// Style
import './index.scss';
import { navigate } from 'gatsby';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function Description() {
  /* ******************************** CONSTANTS ******************************* */

  const { t } = useTranslation();
  const {
    descriptionSection1: { description1 },
    list,
    list1,
    subTitle,
    subDescription,
    ctaText,
    ctaButton,
  } = t('financialEngineering', { returnObjects: true });

  /* ******************************** RENDERING ******************************* */

  return (
    <Container className="description">
      <div className="description-title">
        {list.map((el) => (
          <p key={el?.text} className="support-offer-bullet">
            <span>• </span>
            {el?.title && <span className="medium">{el?.title}</span>}
            {el?.text}
          </p>
        ))}
        <p className="description-desc1">{description1}</p>
        {list1.map((el) => (
          <p key={el?.text} className="support-offer-bullet">
            <span>• </span>
            {el?.title && <span className="medium">{el?.title}</span>}
            {el?.text}
          </p>
        ))}
        <span className="description__subtitle">{subTitle}</span>
        <p className="description__subdesc">{subDescription}</p>
        <span className="description__ctatext">{ctaText}</span>
        <div className="align-c">
          <button
            type="button"
            className="index-hero__cta-button"
            onClick={() => navigate('/select-project')}
          >
            {ctaButton}
          </button>
        </div>
      </div>
    </Container>
  );
}

Description.propTypes = {};

Description.defaultProps = {};

export default Description;
