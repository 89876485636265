/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useI18next } from 'gatsby-plugin-react-i18next';
import axios from 'axios';

// UI lib comoponents

// Local UI components
import FormGenerator from '../../../shared/UIKit/FormGenerator';

// Redux actions and selectors
// @TODO: If using Redux, import actions and selectors

// Helpers & utils
import schema from '../../../shared/FormSchemas/financialEngineeringSchema.json';

// Style
import './index.scss';
import { useCountries } from '../../../shared/Hooks/useData';
import { API_SUPPORT_OFFER_FORM } from '../../../shared/ApIs';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function Form() {
  /* ******************************** HOOKS******************************* */
  const { language } = useI18next();
  const { countries } = useCountries();

  const selectData = useMemo(() => {
    return {
      country: countries.map(({ attributes, id }) => ({
        label: attributes[`name_${language}`],
        value: id,
      })),
    };
  }, [countries, language]);

  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState();
  // Localisation:
  const { t } = useTranslation();
  const {
    formSection: { titleForm },
  } = t('financialEngineering', { returnObjects: true });

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      await axios.post(API_SUPPORT_OFFER_FORM, {
        data: {
          ...data,
          country: countries.find(
            ({ attributes }) => attributes?.name === data?.country,
          )?.id,
        },
      });
      setResponse('SUCCESS');
    } catch (error) {
      setResponse('ERROR');
    }
    setLoading(false);
  };

  useEffect(() => {
    setTimeout(() => {
      setResponse();
    }, 3000);
  }, [response]);

  /* ******************************** RENDERING ******************************* */

  return (
    <div className="form">
      <h2 className="form-title">{titleForm}</h2>
      <FormGenerator
        schema={schema}
        onSubmit={onSubmit}
        loading={loading}
        selectData={selectData}
        response={response}
      />
    </div>
  );
}

Form.propTypes = {};

Form.defaultProps = {};

export default Form;
